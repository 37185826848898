<template>
    <div class="-m-2 wide:-m-4 p-2 wide:p-4 text-base-content" style="height: calc(100% + 32px); width: calc(100% + 32px)">
        <tabs @select="selectTab" :selected="tabs.selected" :tabs="tabs.list">
            <template #additional>
                <div>he</div>
            </template>
            <template #content-creditPacks> 
                <CreditPacks />
            </template>
            <template #content-summary>
                <Summary />
            </template>
            <template #content-allocation>
                <CreditAllocation />
            </template>
            <template #content-creditSource>
                <CreditSource />
            </template>
        </tabs>
    </div>
</template>
<script>
import tabs from "@/components/dashboard-components/tabs";
import CreditPacks from "./credit-packs"
import Summary from "./summary-list"
import CreditAllocation from "./credit-allocation-list"
import CreditSource from "./credit-source-list"
import { checkPermission } from "@shared/utils/functions"

export default {
    title: "Credit Admin",
    name: "credit-admin",
    components: {
        tabs,
        CreditPacks,
        Summary,
        CreditAllocation,
        CreditSource
    },
    data() {
        return {
            tabs: {
                selected: "creditSource",
                list: [
                    {
                        id: "creditSource",
                        label: "Feature",
                    },
                    {
                        id: "creditPacks",
                        label: "Credit Packs",
                    },
                    
                ],
            },
        
        };
    },
    mounted() {
        this.tabs.selected = this.$route?.query?.tab || "creditSource";
        if(this.checkPermission('creditsAllocation.read')){
            this.tabs.list.push({
                        id: "summary",
                        label: "Inventory",
                    },
                    {
                        id: "allocation",
                        label: "Credit Allocation",
                    })
        }
    },
    methods: {
        checkPermission,
        selectTab(id) {
            this.tabs.selected = id;
            this.$router.push({
                path: this.$route.path,
                query: {
                    tab: this.tabs.selected,
                },
            });
        },
    },
  
};

</script>
<style scoped lang='scss'>
@import "@shared/assets/mixins/mixins.scss";
@include tableComponent;
</style>